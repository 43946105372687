<template>
  <section class="app-ecommerce-details">
    <b-alert
      variant="danger"
      :show="item === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'home'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <b-card v-if="item" no-body>
      <b-card-body>
        <b-row class="my-2">
          <b-col
            cols="12"
            md="5"
            class="
              d-flex
              justify-content-center
              mb-2 mb-md-0
            "
          >
          <div class=" align-items-right justify-content-center" v-if="item.journal" style="text-align: center;">
            <b-row>
              <b-col cols="12" >
                <b-img
                style="width: 400px; height: 400px; min-height: 15.85rem"
                :src="getImage(item.journal.image)"
                :alt="`Image of ${item.title}`"
                class="product-img image-book-details"
                fluid
              />
              </b-col>
              <b-col cols="12" v-if="item.journal" style="text-align: center;">
                <div class=" align-items-center ">
              <h2>{{ item.journal.title }}</h2>
            </div>
              </b-col>
            </b-row>
             
            </div>
           
          </b-col>
          <b-col cols="12" md="7">
            <h2>{{ item.title }}</h2>

            <b-card-text class="item-company mb-0">
              <h5>
                <span>By : </span>
                
                  {{ item.authors }}
                
              </h5>
            </b-card-text>
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <b-row>
                <b-col cols="12">
                  <h5>
                    <span>Journal : {{ item.journal.title }}</span>
                  </h5>
                </b-col>
                <b-col cols="12">
                  <h5>
                    <span>Year of publication : {{ item.year.begin }}</span>
                  </h5>
                </b-col>
                <b-col v-if="item.journal" cols="12">
                  <h5>
                    <span>published in : {{ item.journal.title }}</span>
                  </h5>
                </b-col>
                <b-col v-if="item.issue" cols="12">
                  <h5>
                    <span>Issue : {{ item.issue.title }}</span>
                  </h5>
                </b-col>
                <br>
                <b-col cols="12">
                  <h5>
                    <span id="post-text"></span>
                  </h5>
                </b-col>
              </b-row>
            </div>
            <b-card-text
              v-if="(item.abstract !== null) & (item.abstract !== `null`)"
              >{{ item.abstract }}</b-card-text
            >
            <hr />

            <div class="d-flex flex-column flex-sm-row pt-1">
              
              <b-button
                @click="getBook(item.file)"
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-50"
                  :class="{ 'text-danger': false }"
                />
                <span>Read Now</span>
              </b-button>
              <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [
                    'FacebookIcon',
                    'TwitterIcon',
                    'YoutubeIcon',
                    'InstagramIcon',
                  ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import { filesUrl,Url } from "@/main.js";
import Ripple from "vue-ripple-directive";import Quill from "quill";
import ECommerceProductDetailsItemFeatures from "./ECommerceProductDetailsItemFeatures.vue";
import ECommerceProductDetailsRelatedProducts from "./ECommerceProductDetailsRelatedProducts.vue";
import { useEcommerceUi } from "./useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,Quill,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  computed: {
    // counter() {
    //   return this.$store.getters.changeCounter;
    // },
  },
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.gS();
  },
  watch: {
    "$i18n.locale": function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
  },

  methods: {
    async gS() {
      await  fetch(
          `${Url}articles?take=5&skip=0&id=${this.$route.params.id}`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        ).then(response => response.json())
        .then((res) => {
          this.item = {};
          this.item = res.items[0];
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    updateDepartment(x) {
      this.$store.commit("setDepartment", x);
    },
    updateCategory(x) {
      this.$store.commit("setCategory", x);
    },
    updateSub(x) {
      this.$store.commit("setSub", x);
    },
    updateType(x) {
      this.$store.commit("setType", x);
    },
    updateAuthor(x) {
      this.$store.commit("setAuthor", x);
    },
    reset() {
      this.$store.commit("reset");
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    getBook(path) {
      window.open(`${filesUrl}/${path}`, "_blank");
    },
    byDepartment(id) {
      this.reset()
      this.updateDepartment(id)
      this.$router.push("/");
    },
    byCategory(id) {
      this.reset()
      this.updateCategory(id)
      this.$router.push("/");
    },
    bySubCategory(id) {
      this.reset()
      this.updateSub(id)
      this.$router.push("/");
    },
    byAuthor(id) {
      this.reset()
      this.updateAuthor(id)
      this.$router.push("/");
    },
    async downloadFile(path) {
      await this.axios
        .get(`${filesUrl}/${path}`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
            responseType: "blob",
          },
        })
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement("x");
          docUrl.href = FILE;
          docUrl.setAttribute("download", "file.pdf");
          document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
